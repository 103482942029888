const loginTranslation = {
  mode: 'ko',

  loginError: '로그인 오류',
  notAllowedErrorMessage1: '허용되지 않은 계정입니다.',
  notAllowedErrorMessage2: '담당 관리자에게 허용 요청 바랍니다.',
  loginErrorMessage1: '일시적인 서버 및 네트워크 오류입니다.',
  loginErrorMessage2: '잠시 후에 다시 시도해 주세요.',
  loginErrorMessage3: '로그인에 실패했습니다.',
  loginErrorMessage4: '관리자에게 문의 바랍니다.',
  keyLoginErrorMessage1: '키가 일치하지 않습니다.',
  keyLoginErrorMessage2: '다시 확인해 주세요.',
  login: '휴먼스케이프 구글 로그인',
  inputKey: '키 입력',
  keyLogin: '키 로그인',
  logout: '로그아웃',
};

export default loginTranslation;
