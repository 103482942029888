const commonTranslation = {
  mode: 'ko',
  lang: '🇰🇷 한국어',

  cancel: '취소',
  close: '닫기',
  confirm: '확인',
  add: '추가',
  save: '저장',
  update: '수정',
  transfer: '이전',
  delete: '삭제',
  reset: '초기화',
  duplicate: '복사',
  updateDelete: '수정/삭제',
  completeMessage: '되었습니다.',
  cancelMessage: '취소되었습니다.',
  confirmMessage: '확인되었습니다.',
  successMessage: '완료하였습니다.',
  addMessage: '추가되었습니다.',
  updateMessage: '수정되었습니다.',
  transferMessage: '이전되었습니다.',
  deleteMessage: '삭제되었습니다.',
  duplicateMessage: '복사 완료!',
  validateMessage: '을(를) 입력해 주세요.',
  invalidInputBarcodeMessage: '바코드는 11자여야 합니다.',

  download: '다운로드',
  excelDownload: '액셀 다운로드',
  print: '인쇄',
  languageSetting: '언어 설정',

  title: 'MDA 마미박스 어드민',
  selectHospital: '병원 선택',
  selectHospitalRoom: '진료실 선택',
  selectDate: '날짜 선택',
  inputBarcode: '바코드 입력',
  searchHospital: '병원 검색',
  searchTypeName: '유형 이름 검색',
  search: '검색',
  date: '기간',
  startSearchDate: '검색 시작일',
  endSearchDate: '검색 종료일',

  new: '신규',
  total: '총',
  now: '현재',
  dataCount: '개',
  dataFilteredMessage1: '개 (',
  dataFilteredMessage2: '개의 데이터에서 필터됨)',
  noInfo: '정보 없음',
  status: '상태',
};

export default commonTranslation;
