const commonTranslation = {
  mode: 'en',
  lang: '🇺🇸 English',

  cancel: 'Cancel',
  close: 'Close',
  confirm: 'Confirm',
  add: 'Add',
  save: 'Save',
  update: 'Update',
  transfer: 'Transfer',
  delete: 'Delete',
  reset: 'Reset',
  duplicate: 'Duplicate',
  updateDelete: 'Update/Delete',
  completeMessage: 'completed.',
  cancelMessage: 'Canceled.',
  confirmMessage: 'Confirmed.',
  successMessage: 'Completed successfully.',
  addMessage: 'Added.',
  updateMessage: 'Updated.',
  transferMessage: 'Transferred.',
  deleteMessage: 'Deleted.',
  duplicateMessage: 'Duplicate complete!',
  validateMessage: 'is required.',
  invalidInputBarcodeMessage: 'Barcode must be 11 characters.',

  download: 'Download',
  excelDownload: 'Download Spreadsheet',
  print: 'Print',
  languageSetting: 'Language',

  title: 'MDA: MommyBox Admin',
  selectHospital: 'Select Account',
  selectHospitalRoom: 'Select Room',
  selectDate: 'Select Date',
  inputBarcode: 'Enter Barcode',
  searchHospital: 'Search Account',
  searchTypeName: 'Search Type ',
  search: 'Search',
  date: 'Date',
  startSearchDate: 'Start Date',
  endSearchDate: 'End Date',

  new: 'New',
  total: 'Total',
  now: 'Now',
  dataCount: 'items',
  dataFilteredMessage1: 'items (',
  dataFilteredMessage2: 'items filtered from total)',
  noInfo: 'No information',
  status: 'Status',
};

export default commonTranslation;
