const recordingTranslation = {
  mode: 'ko',
  menu: '초음파',
  recordingMenu: '영상 리스트',
  captureMenu: '캡쳐 리스트',
  migrationMenu: '영상 이전 시스템',
  mothersMenu: '마더스 다이어리',
  humancityMenu: '휴먼시티',

  recordingListTitle: '초음파 영상 리스트',
  captureListTitle: '초음파 캡쳐 리스트',
  mothersListTitle: '마더스 리스트',
  humancityListTitle: '휴먼시티 리스트',
  searchRecording: '초음파 영상 검색',
  searchCapture: '초음파 캡쳐 검색',
  searchAppUser: '유저 검색',
  searchMothers: '마더스 리스트 검색',
  searchHumancity: '휴먼시티 리스트 검색',
  selectMommytalk: '마미톡 사용자 검색',
  selectMommytalkHospital: '마미톡 사용자 병원 선택',
  selectMommytalkUser: '마미톡 사용자 이름 선택',
  userDetailInfo: '사용자의 상세 정보',
  howToSearch: '검색 도움말',
  help: '도움말',
  transfer: '마미톡으로 이전',

  seq: '번호',
  thumbnail: '썸네일',
  capture: '캡쳐',
  captureImage: '캡쳐 이미지',
  barcode: '바코드',
  videoLength: '비디오 길이',
  hospital: '병원',
  device: '마미박스',
  recordedAt: '촬영일시',
  registeredAt: '등록일시',
  public: '공개',
  private: '비공개',

  chartNumber: '차트 번호',
  name: '이름',
  babyName: '아기 이름',
  id: '아이디',
  nickName: '닉네임',
  recordingCount: '영상 개수',
  signUpDate: '가입일',
  dateOfBirth: '출산 예정일',
  treatedHospital: '진료 받은 병원명',
  phoneNumber: '전화번호',
  email: '이메일',
  status: '회원상태',
  leave: '탈퇴함',
  normal: '정상',
  hospitalName: '병원명',
  fileName: '파일명',
  fileSize: '파일 크기',
  select: '선택',
  mothersName: '마더스 사용자 이름',
  mothersPhone: '마더스 사용자 전화번호',
  mothersId: '마더스 사용자 아이디',
  humancityName: '휴먼시티 사용자 이름',
  humancityPhone: '휴먼시티 사용자 전화번호',
  humancityId: '휴먼시티 사용자 아이디',
  mommytalkName: '마미톡 사용자 이름',
  mommytalkPhone: '마미톡 사용자 전화번호',
  mommytalkBarcode: '마미톡 사용자 아기 바코드',
  mommytalkHospital: '마미톡 사용자 병원 이름',

  thumbnailMessage: '클릭 시 새 창에서 영상 재생',
  howToSearchMessage1: '1. 바코드 전체(11자리)를 입력하여 검색',
  howToSearchMessage2: '2. 디바이스 이름을 입력하여 검색 (ex: MB2-B00057)',
  howToSearchMessage3: '3. 병원을 선택한 후에 병실명을 일부 입력하여 검색',
  noBarcodeMessage: '바코드가 없는 사용자 입니다.',
};

export default recordingTranslation;
