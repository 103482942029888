export { default as common } from './translation/common';
export { default as login } from './translation/login';
export { default as dashboard } from './translation/dashboard';
export { default as monitoring } from './translation/monitoring';
export { default as cs } from './translation/cs';
export { default as hospital } from './translation/hospital';
export { default as ultrasound } from './translation/ultrasound';
export { default as device } from './translation/device';
export { default as crm } from './translation/crm';
export { default as admin } from './translation/admin';
