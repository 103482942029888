const recordingTranslation = {
  mode: 'en',
  menu: 'Ultrasound',
  recordingMenu: 'Video List',
  captureMenu: 'Photo List',
  migrationMenu: 'Video Migration System',
  mothersMenu: 'Mothers Diary',
  humancityMenu: 'Human City',

  recordingListTitle: 'Ultrasound Video List',
  captureListTitle: 'Ultrasound Photo List',
  mothersListTitle: 'Mothers List',
  humancityListTitle: 'Human City List',
  searchRecording: 'Search Ultrasound Videos',
  searchCapture: 'Search Ultrasound Photos',
  searchAppUser: 'Search User',
  searchMothers: 'Search Mothers List',
  searchHumancity: 'Search Human City List',
  selectMommytalk: 'Search Mommytalk Users',
  selectMommytalkHospital: 'Select Account',
  selectMommytalkUser: 'Select Mommytalk User Name',
  userDetailInfo: 'User Detail',
  howToSearch: 'Search Help',
  help: 'Help',
  transfer: 'Transfer to Mommytalk',

  seq: 'Seq',
  thumbnail: 'Thumbnail',
  capture: 'Photo',
  captureImage: 'Photo Image',
  barcode: 'Barcode',
  videoLength: 'Video Length',
  hospital: 'Account',
  device: 'Device',
  recordedAt: 'Recording Started at',
  registeredAt: 'Uploaded at',
  public: 'Visible',
  private: 'Hidden',

  chartNumber: 'Chart Number',
  name: 'Name',
  babyName: 'Baby Name',
  id: 'ID',
  nickName: 'Nickname',
  recordingCount: '# of Videos',
  signUpDate: 'Sign-up Date',
  dateOfBirth: 'Due',
  treatedHospital: 'Account',
  phoneNumber: 'Phone Number',
  email: 'Email',
  status: 'Member Status',
  leave: 'Left',
  normal: 'Normal',
  hospitalName: 'Account Name',
  fileName: 'File Name',
  fileSize: 'File Size',
  select: 'Select',
  mothersName: 'Mothers User Name',
  mothersPhone: 'Mothers User Phone Number',
  mothersId: 'Mothers User ID',
  humancityName: 'Human City User Name',
  humancityPhone: 'Human City User Phone Number',
  humancityId: 'Human City User ID',
  mommytalkName: 'Mommytalk User Name',
  mommytalkPhone: 'Mommytalk User Phone Number',
  mommytalkBarcode: 'Mommytalk User Baby Barcode',
  mommytalkHospital: 'Mommytalk User Hospital Name',

  thumbnailMessage: 'Click to play video in a new window',
  howToSearchMessage1: '1. Enter the full barcode (11 digits)',
  howToSearchMessage2: '2. Enter the device name (ex: MB2-B00057)',
  howToSearchMessage3: '3. Select an account name and Enter the location',
  noBarcodeMessage: 'User does not have a barcode.',
};

export default recordingTranslation;
