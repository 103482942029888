const crmTranslation = {
  mode: 'en',
  menu: 'CRM',

  crm: 'CRM Records',
  detail: 'Detailed Records',
  crmTitle: 'CRM History',
  crmTypeTitle: 'CRM Type Management',
  sendTaChannel: 'Notify Technical Support Channel',
  addCrm: 'Add Record',
  editCrm: 'Edit Record',
  deleteCrm: 'Delete Record',
  slackLink: 'Slack Link',
  manageType: 'Manage Type',
  addType: 'Add Type',
  searchType: 'Search Type Name',
  selectType: 'Select Type',
  selectStatus: 'Select Status',
  insertDescription: 'Insert Description',
  sendSlack: 'Send Message to Slack',

  seq: 'Number',
  hospital: 'Account',
  hospitalName: 'Account Name',
  unassignedHospital: 'Unassigned Account',
  hospitalRoom: 'Location',
  type: 'Type',
  typeName: 'Type Name',
  crmStatus: 'Status',
  createdAt: 'Created At',
  updatedAt: 'Updated At',
  actor: 'PIC',
  description: 'Description',
  processing: 'In Progress',
  keep: 'Hold',
  complete: 'Closed',
  archive: 'Archive',

  crmDownload: 'Download CRM History',
  crmExcel: 'MDA-CRM_History.xlsx',

  deleteMessage: 'Delete CRM history number?',
  deleteTypeMessage: 'Delete type?',
};

export default crmTranslation;
