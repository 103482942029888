import { initReactI18next } from 'react-i18next';

import i18n, { use } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import * as ko from './ko';
import * as en from './en';

const resources = {
  ko: {
    ...ko,
  },
  en: {
    ...en,
  },
} as const;

use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    load: 'languageOnly',
    supportedLngs: ['ko', 'en'],
    fallbackLng: ['ko-KR', 'en-US'],
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    },
  });

export default i18n;
