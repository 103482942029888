const dashBoardTranslation = {
  mode: 'en',
  menu: 'Dashboard',

  recordingDailyCount: 'Daily Video Recorded',
  recordingMonthlyCount: 'Monthly Video Recorded',
  barcodeCountByHospital: 'Barcode Recorded by Account',

  hospital: 'Account',
  city: 'State',
  barcodeCount: '# of Barocde Recorded',
  recordingCount: '# of Video Recorded',
};

export default dashBoardTranslation;
