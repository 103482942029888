const dashBoardTranslation = {
  mode: 'ko',
  menu: '대시보드',

  recordingDailyCount: '일일 녹화 수',
  recordingMonthlyCount: '월별 녹화 수',
  barcodeCountByHospital: '병원별 바코드 수',

  hospital: '병원명',
  city: '시/군/구',
  barcodeCount: '바코드 수',
  recordingCount: '녹화 수',
};

export default dashBoardTranslation;
