const loginTranslation = {
  mode: 'en',

  loginError: 'Login Error',
  notAllowedErrorMessage1: 'Unauthorized account.',
  notAllowedErrorMessage2: 'Error: Please contact the administrator.',
  loginErrorMessage1: 'Temporary server or network error.',
  loginErrorMessage2: 'Please try again later.',
  loginErrorMessage3: 'Login failed.',
  loginErrorMessage4: 'Error: Please contact the administrator.',
  keyLoginErrorMessage1: 'Login key does not match.',
  keyLoginErrorMessage2: 'Please check again.',
  login: 'Humanscape Member Login',
  inputKey: 'Enter Key',
  keyLogin: 'Key Authentication',
  logout: 'Logout',
};

export default loginTranslation;
