const adminTranslation = {
  mode: 'ko',
  menu: '관리자',
  toolMenu: '도구 모음',

  createTestBarcodeTitle: '테스트용 바코드 발급',
  uploadRecordingTitle: '초음파 영상 신규 업로드',

  barcode: '바코드',
  hospital: '영상 등록 병원',
  recordedAt: '영상 업로드 날짜',
  hospitalRoom: '진료실',
  selectHospitalRoom: '병실/박스 선택',
  create: '생성',
  upload: '업로드',
  uploadRecording: '영상 업로드',

  createTestBarcode: '테스트 바코드 생성 완료',
  completeUploadRecording: '초음파 영상 업로드 완료',
  failUploadRecording: '초음파 영상 업로드 실패',
  uploadCompleteMessage: '파일 업로드 완료, 영상 정보를 갱신 중입니다.',
  uploadFailMessage: '영상 정보 갱신 실패, 영상에 문제가 있을 수 있습니다.',
  barcodeValidateMessage: '11자리 숫자만 입력해 주세요.',
  recordingValidateMessage: '올바른 형식의 영상 파일을 업로드해 주세요.',

  excelHospital: '병원',
  excelDevice: '마미박스',
  excelRecording: '초음파 영상',
  excelCapture: '초음파 영상 캡쳐',
  googleSheetDownloadTitle: '데이터 엑셀 다운로드',
  googleSheetDownloadSelection: '엑셀 다운로드 항목',
  selectExcelType: '항목 선택',
  downloadExcel: '엑셀 다운로드',
};

export default adminTranslation;
