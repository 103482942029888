const adminTranslation = {
  mode: 'en',
  menu: 'Administrator',
  toolMenu: 'Tools',

  createTestBarcodeTitle: 'Generate Test Barcode',
  uploadRecordingTitle: 'Upload Ultrasound Video',

  barcode: 'Barcode',
  hospital: 'Account',
  recordedAt: 'Upload Date',
  hospitalRoom: 'Room',
  selectHospitalRoom: 'Select Room/Mommybox',
  create: 'Generate',
  upload: 'Upload',
  uploadRecording: 'Upload Video',

  createTestBarcode: 'Success: Test Barcode Generated.',
  completeUploadRecording: 'Completed: Ultrasound Video Uploaded',
  failUploadRecording: 'Error: Ultrasound Video Upload Failed',
  uploadCompleteMessage: 'Complete: file uploaded, updating video information.',
  uploadFailMessage:
    'Error: Failed to update video information, there may be an issue with the video.',
  barcodeValidateMessage: 'Error: your input should be an 11-digit numerical code',
  recordingValidateMessage: 'Please upload a video file in the correct format.',

  excelHospital: 'hospital',
  excelDevice: 'mommybox',
  excelRecording: 'ultrasound',
  excelCapture: 'ultrasound capture',
  googleSheetDownloadTitle: 'Excel Download',
  googleSheetDownloadSelection: 'Excel Download Items',
  selectExcelType: 'Select Item',
  downloadExcel: 'Download Excel',
};

export default adminTranslation;
