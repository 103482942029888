const crmTranslation = {
  mode: 'ko',
  menu: 'CRM',

  crm: 'CRM 기록',
  detail: '상세 기록',
  crmTitle: 'CRM 내역',
  crmTypeTitle: 'CRM 유형 관리',
  sendTaChannel: '기술 지원 채널에 알림',
  addCrm: '기록 추가',
  editCrm: '기록 수정',
  deleteCrm: '기록 삭제',
  slackLink: '슬랙 링크',
  manageType: '유형 관리',
  addType: '유형 추가',
  searchType: '유형 이름 검색',
  selectType: '유형 선택',
  selectStatus: '상태 선택',
  insertDescription: '내용 입력',
  sendSlack: '슬랙에 메시지 전송',

  seq: '번호',
  hospital: '병원',
  hospitalName: '병원명',
  unassignedHospital: '병원 미지정',
  hospitalRoom: '진료실',
  type: '유형',
  typeName: '유형 이름',
  crmStatus: '처리 상태',
  createdAt: '생성일시',
  updatedAt: '수정일시',
  actor: '처리자',
  description: '내용',
  processing: '진행중',
  keep: '보류',
  complete: '종료',
  archive: '보관',

  crmDownload: 'CRM 내역 다운로드',
  crmExcel: 'MDA-CRM_내역.xlsx',

  deleteMessage: '번 CRM 내역을 삭제하시겠습니까?',
  deleteTypeMessage: '유형을 삭제하시겠습니까?',
};

export default crmTranslation;
